import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isLogin, logout} from '../middlewares/auth';
import {PermissionContext} from './PermissionContext';
import { isNullOrUndefined } from '../utils/functionsUtils';

const PrivateRoute = ({
  component: Component,
  when,
  whenMultiple = [],
  logOutUser = true,
  ...rest
}) => {
  const { hasPermission } = useContext(PermissionContext);
  
  let isAllowed = true;

  if (!isNullOrUndefined(when)) isAllowed = hasPermission(when);

  if (whenMultiple.length > 0) {
    whenMultiple.forEach(element => {
      if (!hasPermission(element)) {
        isAllowed = false;
        return;
      }
    });
  }
  
  if (isAllowed) {
    return (<Route
      {...rest}
      render={(props) =>
        isLogin() ? <Component {...props} /> : <Redirect to="/" />
      }
    />)
  }
  else {
    if (logOutUser) {
      logout();
      return (<Redirect to="/" />) 
    }
    return (<Redirect to="/" />)
  }
};

export default PrivateRoute;
