export const buttonTypes = {
    Primary: 'btn-primary',
    Danger: 'btn-danger',
    Success: 'btn-success',
}

export const buttonIcon = {
    Upload: 'fas fa-upload',
    UploadFile: 'fas fa-file-upload',
    DownloadFile: 'fas fa-file-download',
    Download: 'fas fa-download',
    Add: 'fas fa-plus',
    FileExport: 'fas fa-file-export',
    FileImport: 'fas fa-file-import',
    FileContract: 'fas fa-file-contract',
    Clipboard: 'fas fa-clipboard-list',
    Refresh: 'fas fa-undo',
    Info: 'fas fa-info',
    List: 'fas fa-list',
    Columns: 'fas fa-columns',
    Edit: 'fas fa-edit',
    Arrow_LeftReply: 'fas fa-reply',
    Arrow_AngleDown: 'fas fa-angle-down',
    Arrow_AngleRight: 'fas fa-angle-right',
    Trash: 'fas fa-trash-alt',
    Inbox: 'fas fa-inbox',
    Exchange: 'fas fa-exchange-alt',
    TruckMoving: 'fas fa-truck-moving',
    Sign:'fas fa-sign-in-alt fa-sm',
    Barcode: 'fas fa-barcode',
    Forklift: 'fas fa-dolly-flatbed',
}